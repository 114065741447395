/**
 * @generated SignedSource<<c6a905325dd4a3e835c28883ea4f3ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type dataValidationRulesQuery$variables = {
  connectorComponentKind: string;
  connectorComponentName: string;
  connectorComponentVersion: string;
  owner: string;
};
export type dataValidationRulesQuery$data = {
  readonly ruleLibraryItems: ReadonlyArray<{
    readonly active: boolean | null;
    readonly categories: ReadonlyArray<string> | null;
    readonly description: string;
    readonly displayName: string;
    readonly name: string;
    readonly provider: string | null;
    readonly rliGeneralProperties: ReadonlyArray<{
      readonly defaultValue: string | null;
      readonly defaultValues: ReadonlyArray<string | null> | null;
      readonly externalObjectType: string | null;
      readonly order: number | null;
      readonly parameterName: string;
      readonly uiReadOnly: boolean;
      readonly visibility: boolean;
    }> | null;
    readonly rliSpecificProperties: ReadonlyArray<{
      readonly defaultValue: string | null;
      readonly defaultValues: ReadonlyArray<string | null> | null;
      readonly externalObjectType: string | null;
      readonly order: number | null;
      readonly parameterName: string;
      readonly uiReadOnly: boolean;
      readonly visibility: boolean;
    }> | null;
    readonly rliType: string | null;
    readonly rule: {
      readonly checkId: number | null;
      readonly checkStatus: string | null;
      readonly checkStatusMessage: string | null;
      readonly connectorComponentRef: {
        readonly kind: string | null;
        readonly name: string | null;
        readonly version: string | null;
      } | null;
      readonly containerId: number | null;
      readonly datastoreId: number | null;
      readonly name: string;
      readonly notificationProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
      readonly ruleGeneralProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
      readonly ruleSpecificProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
    } | null;
    readonly status: string | null;
    readonly tableRef: string;
    readonly version: string;
  }>;
};
export type dataValidationRulesQuery = {
  response: dataValidationRulesQuery$data;
  variables: dataValidationRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectorComponentKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectorComponentName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectorComponentVersion"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "owner"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "parameterName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "defaultValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "defaultValues",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalObjectType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "order",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "uiReadOnly",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "visibility",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyValues",
    "storageKey": null
  }
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "kind",
            "variableName": "connectorComponentKind"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "connectorComponentName"
          },
          {
            "kind": "Variable",
            "name": "version",
            "variableName": "connectorComponentVersion"
          }
        ],
        "kind": "ObjectValue",
        "name": "connectorComponentRef"
      },
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      }
    ],
    "concreteType": "RuleLibraryItem",
    "kind": "LinkedField",
    "name": "ruleLibraryItems",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableRef",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categories",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rliType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleLibraryItemParameter",
        "kind": "LinkedField",
        "name": "rliGeneralProperties",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleLibraryItemParameter",
        "kind": "LinkedField",
        "name": "rliSpecificProperties",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rule",
        "kind": "LinkedField",
        "name": "rule",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ConnectorComponentRef",
            "kind": "LinkedField",
            "name": "connectorComponentRef",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "ruleGeneralProperties",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "ruleSpecificProperties",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "notificationProperties",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkStatusMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datastoreId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "containerId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "dataValidationRulesQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "dataValidationRulesQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "e67b7c3ba26d7592d70fc5260eee96a2",
    "id": null,
    "metadata": {},
    "name": "dataValidationRulesQuery",
    "operationKind": "query",
    "text": "query dataValidationRulesQuery(\n  $owner: String!\n  $connectorComponentKind: String!\n  $connectorComponentName: String!\n  $connectorComponentVersion: String!\n) {\n  ruleLibraryItems(owner: $owner, connectorComponentRef: {kind: $connectorComponentKind, name: $connectorComponentName, version: $connectorComponentVersion}) {\n    name\n    version\n    provider\n    displayName\n    description\n    tableRef\n    categories\n    active\n    status\n    rliType\n    rliGeneralProperties {\n      parameterName\n      defaultValue\n      defaultValues\n      externalObjectType\n      order\n      uiReadOnly\n      visibility\n    }\n    rliSpecificProperties {\n      parameterName\n      defaultValue\n      defaultValues\n      externalObjectType\n      order\n      uiReadOnly\n      visibility\n    }\n    rule {\n      name\n      connectorComponentRef {\n        kind\n        name\n        version\n      }\n      ruleGeneralProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      ruleSpecificProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      notificationProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      checkStatus\n      checkStatusMessage\n      checkId\n      datastoreId\n      containerId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6aa3df38122b6a389de7b8fb5f018f1";

export default node;
